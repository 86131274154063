import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';


@Component({
  selector: 'complexity-modal',
  templateUrl: './complexity-modal.component.html',
  styleUrl: './complexity-modal.component.scss'
})
export class ComplexityModalComponent {
  
  constructor(
    public dialogRef: MatDialogRef<ComplexityModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toast: ConfirmationToastComponent,
    private assessmentService: AssessmentService,
  ) {
    dialogRef.addPanelClass("something")
    dialogRef.updatePosition({
      top: "0px",
      right: "0px"
    })
    dialogRef.updateSize("680px", "100%")
  }
}