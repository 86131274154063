import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser } from "@angular/common";
import { ReportService } from 'src/app/services/report.service';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-request-support',
  templateUrl: './request-support.component.html',
  styleUrl: './request-support.component.scss'
})
export class RequestSupportComponent {
  faWarning = faWarning;

  currentUser: any;
  private readonly fireRecord: any = this.data.fireRecord;
  existingReport: any = null;

  displayedColumns!: string[];

  team_needs: any[];
  leadList: any[] = [];
  skillList: any[] = [];
  disableTeamOption = false;

  formValid = false;


  fireForm = this.formBuilder.group({
    details: [""],
    other_skill: [""],
    support_type: [""],
  });



  constructor(
    public dialogRef: MatDialogRef<RequestSupportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    private readonly reportService: ReportService,
    private readonly dialogService: DialogService,
  ) {
    this.titleService.setTitle("BAER - Request support")
    dialogRef.disableClose = true;

    this.fireForm.valueChanges.subscribe(value => {
      this.checkValidForm();
    })

    this.team_needs = this.assessmentService.getBAERTeamNeeds();
    this.userService.getSkillList().subscribe(skillsList => {
      skillsList.forEach((skill: any) => {
        this.skillList.push({ name: skill.text, checked: false });
      })
      this.skillList.push({ name: "Other", checked: false })
    });
  }

  checkValidForm() {
    this.formValid = true
    let invalidForm = true
    if (this.fireForm.value.support_type == "skills") {
      for (let item of this.skillList) {
        if (item.checked) {
          invalidForm = false
          break
        }
      }
      if (this.skillList[this.skillList.length - 1].checked) {
        if (this.fireForm.value.other_skill == "") {
          invalidForm = true;
        }
      }
      if (invalidForm) {
        this.formValid = false
      }
    }
  }

  ngOnInit(): void { 
    if (this.data.report == null) {
      this.disableTeamOption = true;
    }

    
  }

  checkExistingReport(): void {
    if (this.data.report) {
      this.existingReport = this.data.report.supports.find((support: any) => {
        return support.support_type == this.fireForm.value.support_type && !support.support_completed
      })
    } else {
      this.existingReport = this.data.fireRecord.supports.find((support: any) => {
        return support.support_type == this.fireForm.value.support_type && !support.support_completed
      })
    }
    console.log(this.existingReport)
    if (this.existingReport) {
      this.fireForm.patchValue({
        details: this.existingReport.additional_details,
        other_skill: this.existingReport.other_skill
      })
      this.skillList.map((item: any) => {
        item.checked = this.existingReport.support_skills.search(item.name) != -1
        if (item.name == "Other") {
          item.checked = !!this.existingReport.other_skill
        }
        return {name: item.name, checked: item.checked}
      })
      console.log(this.skillList)
      //this.skillList = a
    }
  }

  sumbitRequest(): void {
    let skillSetString = this.skillList.filter((item: any) => {
      return item.checked && item.name != "Other";
    }).map((item: any) => {
      return item.name;
    }).join(", ")

    if (this.fireForm.value.other_skill) {
      if (skillSetString != "") skillSetString += ", "
      skillSetString += this.fireForm.value.other_skill
    }

    if (this.fireForm.value.support_type == "Team Leader") {
      skillSetString = ""
    }

    if (this.existingReport) {
      this.existingReport.support_skills = skillSetString
      this.existingReport.additional_details = this.fireForm.value.details as string | undefined
      this.existingReport.support_type = this.fireForm.value.support_type as string | undefined

      if (this.data.report) {
        this.reportService.updateReport({
          report_id: this.data.report.report_id,
          supports: [this.existingReport]
        }).subscribe( {
          next: (response) => {
            this.toast.openSuccessSnackBar(`The interregional support request has been updated`);
            this.dialogRef.close(true);
          },
          error: (response) => {
            this.toast.openErrorSnackBar(`There was an error requesting support for ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
            this.dialogRef.close();
          },
          complete: () => {
            this.dialogService.closeSpinner();
          }
        })
      } else {
        this.assessmentService.editAssessment({
          assessment_id: this.fireRecord.assessment_id,
          supports: [this.existingReport]
        }).subscribe({
          next: (response) => {
            this.toast.openSuccessSnackBar(`A support request has been submitted for ${this.fireRecord.baer_name}.`);
            this.dialogRef.close(true);
          },
          error: (response) => {
            this.toast.openErrorSnackBar(`There was an error requesting support for ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
            this.dialogRef.close();
          },
          complete: () => {
            this.dialogService.closeSpinner();
          }
        })
      }
    } else {
      if (this.data.report) {
        this.reportService.requestSupport({
          report_id: this.data.report.report_id,
          support_details: this.fireForm.value.details as string | undefined,
          support_skills: skillSetString,
          support_needed_type: this.fireForm.value.support_type as string | undefined,
        }).subscribe({
          next: (response) => {
            this.toast.openSuccessSnackBar(`A support request has been submitted for ${this.fireRecord.baer_name}.`);
            this.dialogRef.close(true);
          },
          error: (response) => {
            this.toast.openErrorSnackBar(`There was an error requesting support for ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
            this.dialogRef.close();
          },
          complete: () => {
            this.dialogService.closeSpinner();
          }
        })
      } else {
        this.assessmentService.requestSupport({
          assessment_id: this.fireRecord.assessment_id,
          support_details: this.fireForm.value.details as string | undefined,
          support_skills: skillSetString,
          support_needed_type: this.fireForm.value.support_type as string | undefined,
        }).subscribe({
          next: (response) => {
            this.toast.openSuccessSnackBar(`A support request has been submitted for ${this.fireRecord.baer_name}.`);
            this.dialogRef.close(true);
          },
          error: (response) => {
            this.toast.openErrorSnackBar(`There was an error requesting support for ${this.fireRecord.baer_name}.`, "Please try editing the assessment again.");
            this.dialogRef.close();
          },
          complete: () => {
            this.dialogService.closeSpinner();
          }
        })
      }
    }

    
  }

  updateSkill(skill: any) {
    skill.checked = !skill.checked
    this.checkValidForm();
  }
}