import {NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MatTableModule} from '@angular/material/table'
import {MatChip} from '@angular/material/chips'
import {MatPaginator} from '@angular/material/paginator'
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSortModule} from '@angular/material/sort';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {DashboardComponent} from './routes/dashboard/dashboard.component';
import {NavComponent} from './components/nav/nav.component';
import {BannerComponent} from './components/banner/banner.component';
import {FireTrackerComponent, MergeSnackbarComponent} from './routes/fire-tracker/fire-tracker.component';
import {NewFireComponent} from './routes/fire-tracker/new-fire/new-fire.component';
import {EditFireComponent} from './routes/fire-tracker/edit-fire/edit-fire.component';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel} from '@angular/material/snack-bar';
import {
  ConfirmationToastComponent,
  ErrorToastComponent,
  SuccessToastComponent
} from './components/confirmation-toast/confirmation-toast.component';
import {DataManipulationComponent} from './routes/data-manipulation/data-manipulation.component';
import {RosterComponent} from './routes/roster/roster.component';
import {DeleteUserSnackbarComponent, UserRosterComponent} from './routes/roster/user-roster/user-roster.component';
import {PhonePipe} from 'src/pipes/phone.pipe';
import {ForestFilterPipe} from 'src/pipes/forest_filter.pipe';
import {MergeFiresComponent} from './routes/fire-tracker/merge-fires/merge-fires.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {ComplexityModalComponent} from './routes/fire-tracker/complexity-modal/complexity-modal.component';
import {LineBreakPipe} from 'src/pipes/line_break.pipe';
import {BoolPipe} from 'src/pipes/bool.pipe';
import {ProgressSpinnerComponent} from './components/progress-spinner/progress-spinner.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {UserComponent} from './routes/user/user.component';
import {ViewContactComponent} from './routes/user/view-contact/view-contact.component';
import {ViewAvailabilityComponent} from './routes/user/view-availability/view-availability.component';
import {ViewSkillsComponent} from './routes/user/view-skills/view-skills.component';
import {EditContactComponent} from './routes/user/edit-contact/edit-contact.component';
import {EditAvailabilityComponent} from './routes/user/edit-availability/edit-availability.component';
import {EditSkillsComponent} from './routes/user/edit-skills/edit-skills.component';
import {ViewAssignmentsComponent} from './routes/user/view-assignments/view-assignments.component';
import {NewUserComponent} from './routes/user/new-user/new-user.component';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {DeleteUserComponent} from './routes/user/delete-user/delete-user.component';
import {LoginRedirectComponent} from "./routes/login-redirect/login-redirect.component";
import {LoginComponent} from "./routes/login/login.component";
import {MatGridList, MatGridListModule, MatGridTile} from "@angular/material/grid-list";
import {FocusableDirective} from './directive/focusable.directive';
import {RemoveUserComponent} from './routes/user/remove-user/remove-user.component';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {HeaderMenuComponent} from './components/header/header-menu/header-menu.component';
import {EditSelfComponent} from './routes/user/edit-self/edit-self.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {EditAssessmentComponent} from './routes/assessments/edit-assessment/edit-assessment.component';
import {AssessmentsComponent} from './routes/assessments/assessments.component';
import {ReportListComponent} from './routes/assessments/report-list/report-list.component';
import {StatusBadgePipe} from 'src/pipes/status_badge.pipe';
import {RequestSupportComponent} from './routes/assessments/request-support/request-support.component';
import {ViewFireComponent} from './routes/fire-overview/reports-tab/view-fire/view-fire.component';
import {ViewTeamNeedsComponent} from './routes/fire-overview/reports-tab/view-team-needs/view-team-needs.component';
import {ViewReportsComponent} from './routes/fire-overview/reports-tab/view-reports/view-reports.component';
import {
  ViewForestContactsComponent
} from './routes/fire-overview/reports-tab/view-forest-contacts/view-forest-contacts.component';
import {FireOverviewComponent} from './routes/fire-overview/fire-overview.component';
import {ReportsTabComponent} from './routes/fire-overview/reports-tab/reports-tab.component';
import {SupportRequestsTabComponent} from './routes/assessments/support-requests-tab/support-requests-tab.component';
import {BaerAssessmentsTabComponent} from './routes/assessments/baer-assessments-tab/baer-assessments-tab.component';
import {CustomDateAdapter} from "./adapters/custom-date.adapter";
import {ReportStatusBadgePipe} from 'src/pipes/report_status_badge';
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";
import { TeamsTabComponent } from './routes/fire-overview/teams-tab/teams-tab.component';
import { DemobilizeComponent } from './routes/fire-overview/teams-tab/demobilize/demobilize.component';
import { AddTeamMemberComponent } from './routes/fire-overview/teams-tab/add-team-member/add-team-member.component';
import { RemoveMemberComponent } from './routes/fire-overview/teams-tab/remove-member/remove-member.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    NavComponent,
    BannerComponent,
    FireTrackerComponent,
    NewFireComponent,
    EditFireComponent,
    MergeSnackbarComponent,
    MergeFiresComponent,
    ConfirmationToastComponent,
    SuccessToastComponent,
    ErrorToastComponent,
    DataManipulationComponent,
    RosterComponent,
    UserRosterComponent,
    PhonePipe,
    ForestFilterPipe,
    LineBreakPipe,
    BoolPipe,
    StatusBadgePipe,
    ReportStatusBadgePipe,
    ComplexityModalComponent,
    ProgressSpinnerComponent,
    UserComponent,
    ViewContactComponent,
    ViewAvailabilityComponent,
    ViewSkillsComponent,
    ViewAssignmentsComponent,
    EditContactComponent,
    EditAvailabilityComponent,
    EditSkillsComponent,
    NewUserComponent,
    DeleteUserComponent,
    LoginRedirectComponent,
    DeleteUserSnackbarComponent,
    LoginComponent,
    FocusableDirective,
    RemoveUserComponent,
    HeaderMenuComponent,
    EditSelfComponent,
    EditAssessmentComponent,
    AssessmentsComponent,
    ReportListComponent,
    RequestSupportComponent,
    FireOverviewComponent,
    ReportsTabComponent,
    ViewFireComponent,
    ViewTeamNeedsComponent,
    ViewReportsComponent,
    ViewForestContactsComponent,
    SupportRequestsTabComponent,
    BaerAssessmentsTabComponent,
    TeamsTabComponent,
    DemobilizeComponent,
    AddTeamMemberComponent,
    RemoveMemberComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    MatChip,
    MatPaginator,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatSortModule,
    MatButton,
    MatIconButton,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatSnackBarLabel,
    MatSnackBarActions,
    MatSnackBarAction,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    NgxMaskDirective,
    MatGridList,
    MatGridTile,
    TextFieldModule
  ],
  providers: [
    provideAnimationsAsync(),
    ConfirmationToastComponent,
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideClientHydration(),
    ForestFilterPipe,
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: '+0000'}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
