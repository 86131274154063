<header class="baer-header">
    <p id="table" class="site-page-title">BAER Assessments</p>
</header>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" *ngIf="user">
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faFire" style="font-size: 20px;" aria-label="2500-8 Reports"></fa-icon>&nbsp;
            BAER Assessments
        </ng-template>
        <ng-template matTabContent>
            <app-baer-assessments-tab [isWO]="isWO" [isFOCO]="isFOCO" [isROCO]="isROCO" [isTeamLead]="isTeamLead" [user]="user"></app-baer-assessments-tab>
        </ng-template>
    </mat-tab>
    <mat-tab aria-hidden="false" *ngIf="isWO">
        <ng-template mat-tab-label>
            <mat-icon aria-label="Interregional Support Requests">support</mat-icon>&nbsp;
            Interregional Support Requests
        </ng-template>
        <ng-template matTabContent>
            <app-support-requests-tab></app-support-requests-tab>
        </ng-template>
    </mat-tab>
</mat-tab-group>