<mat-card>
    <mat-card-header>
      <mat-card-title>SKILLS & EXPERIENCE</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="skills-form" [formGroup]="skillsForm">
    <div class="title" style="padding-bottom: 20px;">Select your experience level for each role or skill, if any:</div>
        <mat-form-field appearance="outline">
          <mat-label>Team Leader</mat-label>
          <mat-select formControlName="Team Leader">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Assistant Team Leader</mat-label>
          <mat-select formControlName="Assistant Team Leader">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Logistics Coordinator</mat-label>
          <mat-select formControlName="Logistics Coordinator">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Liaison Officer</mat-label>
          <mat-select formControlName="Liaison Officer">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Archaeologist</mat-label>
          <mat-select formControlName="Archaeology">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Engineer</mat-label>
          <mat-select formControlName="Engineer">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Fisheries</mat-label>
          <mat-select formControlName="Fisheries">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Geologist</mat-label>
          <mat-select formControlName="Geology">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>GIS Specialist</mat-label>
          <mat-select formControlName="GIS">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Hydrologist</mat-label>
          <mat-select formControlName="Hydrologist">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Interagency Coordination</mat-label>
          <mat-select formControlName="Interagency Coordinator">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Invasive Species Botany</mat-label>
          <mat-select formControlName="Invasive Species/Botany">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Public Information Officer</mat-label>
          <mat-select formControlName="Public Information Officer">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Recreation Specialist</mat-label>
          <mat-select formControlName="Recreation Specialist">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Safety Officer</mat-label>
          <mat-select formControlName="Safety Officer">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Soil Scientist</mat-label>
          <mat-select formControlName="Soil Scientist">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Wildlife Biologist</mat-label>
          <mat-select formControlName="Wildlife Biologist">
            <mat-option value="-1">--</mat-option>
            @for (exp of exp_levels; track exp) {
            <mat-option [value]="exp.value">{{exp.text}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <div class="title">Other<div class="sub-title">(Other skills and level of experience)</div></div>
        <mat-form-field class="text-area" appearance="outline">
          <textarea matInput formControlName="skills_text" placeholder="Enter description"></textarea>
        </mat-form-field>
        <div class="bar">&nbsp;</div>
        <div class="title">Experience with large or complex fires<div class="sub-title">(150,000+ acres)</div></div>
        <mat-radio-group formControlName="large_fire_exp">
          <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
          <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
        </mat-radio-group>
        <div class="title">Required Qualifications and Equipment.<div class="sub-title">A current Red Card is required to obtain a Resource Order through IROC. Line gear is required of all field going team members.</div></div>
        <div class="title">Current Red Card</div>
        <mat-radio-group formControlName="red_card">
          <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
          <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
        </mat-radio-group>
        <div class="title">Line Gear</div>
        <mat-radio-group formControlName="line_gear">
          <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
          <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
        </mat-radio-group>
        <div class="title">Qualifications<div class="sub-title">(List qualification codes on red card)</div></div>
        <mat-form-field class="text-area" appearance="outline">
          <textarea matInput formControlName="red_card_qualifications" placeholder="Enter description"></textarea>
        </mat-form-field>
        <div class="title">References<div class="sub-title">(List Team Leader or BAER Coordinator references)</div></div>
        <mat-form-field class="text-area" appearance="outline">
          <textarea matInput formControlName="team_references" placeholder="Enter description"></textarea>
        </mat-form-field>
        <div class="title">Any additional comments</div>
        <mat-form-field class="text-area" appearance="outline">
          <textarea matInput formControlName="skills_add_comments" placeholder="Enter description"></textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-dialog-actions>
      <div class="button-row">
        <button class="usa-button" (click)="save()">Save</button>
        <button class="usa-button usa-button--secondary" (click)="cancel()">Cancel</button>
      </div>
    </mat-dialog-actions>
  </mat-card>