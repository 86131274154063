import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faUsers, faUserTie, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrl: './roster.component.scss'
})
export class RosterComponent {
  faUsers = faUsers;
  faUserTie = faUserTie;
  faIdBadge = faIdBadge;

  seeUnassigned: boolean = false;
  user: any;
  filterForest: boolean = false;
  filterRegion: boolean = false;

  constructor(
    public userService: UserService,
    private titleService: Title
  ) {
    this.titleService.setTitle("BAER - Roster")
    this.userService.getUserProfile().then((user) => {
      this.user = user;
      // 0-Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 5-BAER Forest Unit Coordinator
      // 7-Washington Office Director
      // 16-WO Coordination
      // 17-WO Logistics
      const seeUnassigned = user.baer_roles.find((obj: any) => [0, 4, 5, 7, 16, 17].includes(obj.value))
      if (seeUnassigned) this.seeUnassigned = true;

      // 0-BAER Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 16-WO Coordination
      // 17-WO Logistics
      // 22-Washington Office Line Officer
      // 23-Acting Washington Office Line Officer
      const woOffice = user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17, 22, 23].includes(obj.value))

      // 3-BAER Regional Coordinator
      // 12-RO BAER Coordinator
      // 13-RO BAR Coordinator
      // 14-RO READ Coordinator
      // 15-RO Disaster Coordinator
      // 20-Regional Line Officer
      // 21-Acting Regional Line Officer
      const regionOffice = user.baer_roles.find((obj: any) => [3, 12, 13, 14, 15, 20, 21].includes(obj.value))

      if (!woOffice) {
        if (!regionOffice) {
          this.filterForest = true; //forest office or simple user
        }
        else {
          this.filterRegion = true; //region office
        }
      } //else wo office - filter nothing
    })
  }
}
