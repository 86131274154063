<mat-card>
  <mat-card-header>
    <mat-card-title>FOREST CONTACTS</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="fire">
    <mat-card *ngFor="let user of users" class="contact-card">
      <div>
        <div class="user-cell-name">
            <div class="user-icon">
                <p>{{user.first_name[0]}}</p>
            </div>
            <a [routerLink]="['/user']" [queryParams]="{user_id: user?.user_id}"
                class="user-name">{{user.first_name + " " + user.last_name}}</a>
        </div>
        <div class="user-cell-details">
          <mat-chip *ngFor="let role of user.showRoles">{{ role }}</mat-chip>
            <div class="details-number"><mat-icon>phone</mat-icon> {{(user.phone_num | phone) || "--"}}</div>
            <div class="details-email"><mat-icon>email</mat-icon>{{user.email}}</div>
        </div>
    </div>
    </mat-card>
  </mat-card-content>
</mat-card>