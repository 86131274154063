<span class="merge-snackbar"
    style="height: 61px; font-size: 16; font-weight: 600; padding: 20px;" matSnackBarLabel>
    {{length}} fire(s) selected
</span>
<span matSnackBarActions>
    <button *ngIf="length != 0" (click)="mergeDialog()" [disabled]="length === 1"
        style="height: 40px;font-weight: 700;" (click)="snackBarRef.dismissWithAction()"
        mat-flat-button color="accent">Merge Fires</button>
    <button mat-icon-button class="exit_button" style="min-width: 0px;" (click)="snackBarRef.dismiss(); clearSelected();">
        <mat-icon style="color: #ffffff;">close</mat-icon>
    </button>
</span>