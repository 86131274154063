import { Component, OnInit, input, model } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-availability',
  templateUrl: './edit-availability.component.html',
  styleUrl: './edit-availability.component.scss'
})
export class EditAvailabilityComponent implements OnInit {
  edit_availability = model<boolean>();
  refreshUser = input<Subject<void>>();
  user = input<any>();
  editable = input.required<boolean>();
  isAdmin: boolean = false;

  availabilityForm = this.formBuilder.group({
    available_local: [{ value: false, disabled: false }],
    available_region: [{ value: false, disabled: false }],
    available_nation: [{ value: false, disabled: false }],
    conflict_dates: [{ value: "", disabled: false }],
    nis_baer: [false],
    vgis: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toast: ConfirmationToastComponent,
  ) {

    this.userService.getUserProfile().then((user) => {
      // 0-BAER Reporting Tool Administrator
      // 4-BAER National Coordinator
      // 7-Washington Office Director
      // 16-WO Coordination
      // 17-WO Logistics
      const isAdmin = user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17].includes(obj.value))
      if (isAdmin) this.isAdmin = true;
    })
  }

  ngOnInit(): void {
    this.availabilityForm.patchValue({
      available_local: this.user().available_local,
      available_region: this.user().available_region,
      available_nation: this.user().available_nation,
      conflict_dates: this.user().conflict_dates,
      nis_baer: this.user().NIS_BAER,
      vgis: this.user().VGIS,
    })

    if(!this.editable()) {
      this.availabilityForm.get('available_local')?.disable();
      this.availabilityForm.get('available_region')?.disable();
      this.availabilityForm.get('available_nation')?.disable();
      this.availabilityForm.get('conflict_dates')?.disable();
    }
  }

  save() {
    this.userService.editUserDetails({
      user_id: this.user().user_id,
      available_local: this.availabilityForm.value.available_local,
      available_region: this.availabilityForm.value.available_region,
      available_nation: this.availabilityForm.value.available_nation,
      conflict_dates: this.availabilityForm.value.conflict_dates,
      NIS_BAER: this.availabilityForm.value.nis_baer,
      VGIS: this.availabilityForm.value.vgis,
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`User's availability were successfully updated.`);
        this.edit_availability.update((x) => {
          return false;
        });
        this.refreshUser()!.next();
      },
      error: (response) => {
        this.toast.openErrorSnackBar("There was an error updating the user.", "Please try again.");
      },
    })
  }

  cancel() {
    this.edit_availability.update((x) => {
      return false;
    });
  }

}