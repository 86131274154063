import { Component, input, model } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-view-availability',
  templateUrl: './view-availability.component.html',
  styleUrl: './view-availability.component.scss'
})
export class ViewAvailabilityComponent {
  faEdit = faEdit;
  edit_availability = model<boolean>();
  user = input<any>();
  editable = input.required<boolean>();

  edit() {
    this.edit_availability.update((x) => {
      return true;
    });
  }
}
