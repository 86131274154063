<header class="baer-header">
    <div class="breadcrumb"><a routerLink="/assessments">BAER Assessments</a> &gt; {{assessment?.baer_name}}</div>
    <h1 id="table" class="site-page-title"><span class="large-font">{{assessment?.baer_name}}</span></h1>
    <div class="status-div" *ngIf="assessment">
        Assessment Status:
        <mat-chip [ngClass]="'chip-'+assessment.assessment_status_id">
            {{assessment.assessment_status_id | statusBadge}}
        </mat-chip>
    </div>
</header>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" *ngIf="user" [(selectedIndex)]="tabIndex">
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faFileLines" style="font-size: 20px;" aria-label="2500-8 Reports"></fa-icon>&nbsp;
            2500-8 Reports
        </ng-template>
        <ng-template matTabContent>
            <app-reports-tab
                *ngIf="user && assessment && reports !== undefined"
                [fire]="assessment"
                [reports]="reports"
                [updateFireSubject]="updateFireSubject"
                [isEditable]="isEditable" 
                [isTeamLead]="isTeamLead"
                [user]="user"
            ></app-reports-tab>
        </ng-template>
    </mat-tab>
    <mat-tab aria-hidden="false">
        <ng-template mat-tab-label>
            <fa-icon [icon]="faUsers" style="font-size: 20px;" aria-label="All Users"></fa-icon>&nbsp;
            Teams
        </ng-template>
        <ng-template matTabContent>
            <app-teams-tab *ngIf="reports && user && assessment"
                [(reports)]="reports"
                [assessment]="assessment"
                [isEditable]="isEditable" 
                [isTeamLead]="isTeamLead"
                [isWO]="isWO"
                [user]="user"
                [report_num]="report_num()"
            ></app-teams-tab>
        </ng-template>
    </mat-tab>
</mat-tab-group>