<footer class="usa-footer">
    <div class="grid-container usa-footer__return-to-top" *ngIf="isLoggedIn">
        <a href="#" (click)="scrollToTop($event)">Return to top</a>
    </div>
    <div class="usa-footer__primary-section">
        <nav class="usa-footer__nav" aria-label="Footer navigation">
            <ul class="grid-row grid-gap">
                <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                    <a class="usa-footer__primary-link" href="https://www.fs.usda.gov/" target="_blank"
                        rel="noopener noreferrer">US Forest Service</a>
                </li>
                <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                    <a class="usa-footer__primary-link" href="https://www.usda.gov/" target="_blank"
                        rel="noopener noreferrer">USDA.gov</a>
                </li>
                <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                    <a class="usa-footer__primary-link" href="https://www.recreation.gov/" target="_blank"
                        rel="noopener noreferrer">Recreation.gov</a>
                </li>
                <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                    <a class="usa-footer__primary-link" href="https://www.usda.gov/policies-and-links" target="_blank"
                        rel="noopener noreferrer">Policies & Links</a>
                </li>
                <li class="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
                    <a class="usa-footer__primary-link" href="https://www.usa.gov/" target="_blank"
                        rel="noopener noreferrer">USA.gov</a>
                </li>
            </ul>
        </nav>
    </div>

    <div class="usa-footer__secondary-section">
        <div class="grid-container">
            <div class="grid-row grid-gap">
                <div class="usa-footer__logo grid-row mobile-lg:grid-col-6 mobile-lg:grid-gap-2">
                    <div class="mobile-lg:grid-col-auto">
                        <img class="usa-footer__logo-img" src="assets/img/footer-logo.png" alt="footer">
                    </div>
                    <div class="mobile-lg:grid-col-auto">
                        <p class="usa-footer__logo-heading">U.S. Forest Service</p>
                    </div>
                </div>
                <div class="usa-footer__contact-links mobile-lg:grid-col-6">
                    <div class="usa-footer__social-links grid-row grid-gap-1">
                        <div class="grid-col-auto">
                            <a class="usa-social-link usa-social-link--facebook"
                                href="https://www.facebook.com/USForestService" target="_blank"
                                rel="noopener noreferrer" aria-label="Facebook Link">
                                <span>Facebook</span>
                            </a>
                        </div>
                        <div class="grid-col-auto">
                            <a class="usa-social-link usa-social-link--twitter" href="https://twitter.com/forestservice"
                                target="_blank" rel="noopener noreferrer" aria-label="Twitter Link">
                                <span>Twitter</span>
                            </a>
                        </div>
                        <div class="grid-col-auto">
                            <a class="usa-social-link usa-social-link--youtube"
                                href="https://www.youtube.com/user/usdaForestService" target="_blank"
                                rel="noopener noreferrer" aria-label="YouTube Link">
                                <span>YouTube</span>
                            </a>
                        </div>
                        <div class="grid-col-auto">
                            <a class="usa-social-link usa-social-link--rss"
                                href="https://www.fs.usda.gov/pnw/page/available-rss-feeds" target="_blank"
                                rel="noopener noreferrer" aria-label="RSS Link">
                                <span>RSS</span>
                            </a>
                        </div>
                    </div>
                    <h3 class="usa-footer__contact-heading">Enterprise Application Development-CIO</h3>
                    <address class="usa-footer__address">
                        <div class="usa-footer__contact-info grid-row grid-gap">
                            <div class="grid-col-12 fs-footer-address">
                                <a href="tel:#">(800) 832-1355 &nbsp; Email</a>
                            </div>
                        </div>
                    </address>
                </div>
            </div>
        </div>
    </div>
</footer>
