import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, EMPTY, Observable} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private idTokenCache!: string;
  private readonly isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private authService: AuthService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req;
    this.idTokenCache = this.idTokenCache || (this.isBrowser && sessionStorage.getItem('id_token') || '');
    if (this.idTokenCache && req.url.includes('/baer/api/')) {
      request = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + this.idTokenCache)
          .set('x-correlation-id', this.isBrowser ? window.crypto.randomUUID() : '')
      });
    }
    if (this.isBrowser) {
      return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Unauthorized) {
          this.authService.logout();
        }
        throw err;
      }));
    }
    return EMPTY;
  }
}
