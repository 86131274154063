import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { faEdit, faPlus, faUsers, faWarning } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';
import { ForestFilterPipe } from 'src/pipes/forest_filter.pipe';
import { ComplexityModalComponent } from '../../fire-tracker/complexity-modal/complexity-modal.component';
import { EditAssessmentComponent } from '../edit-assessment/edit-assessment.component';
import { RequestSupportComponent } from '../request-support/request-support.component';
import { ReportService } from 'src/app/services/report.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';

@Component({
  selector: 'app-baer-assessments-tab',
  templateUrl: './baer-assessments-tab.component.html',
  styleUrls: ['./baer-assessments-tab.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class BaerAssessmentsTabComponent implements OnInit {
  //icons
  faEdit = faEdit;
  faUsers = faUsers;
  faPlus = faPlus;
  faWarning = faWarning;

  //table variables
  pageNumber = 0;
  pageSize = 10;
  length = 0;
  sort = "updated_on"
  sortDirection = "desc"
  selectedForestFlag = false;
  assessments = new MatTableDataSource<any>();
  expandedAssessments: any;
  displayedColumns!: string[];

  //dropdown lists
  regions: any;
  statuses: any;
  complexities: any;
  team_leads: any;

  //access
    @Input() isTeamLead: boolean = false;
    @Input() isROCO: boolean = false;
    @Input() isFOCO: boolean = false;
    @Input() isWO: boolean = false;
    @Input() user: any;

  //forest name filter
  forestNameList: string[];
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  forest_name = new FormControl("")
  selectedRegion: string | undefined = undefined;

  //search bar items
  filters = {
    region: null,
    forest_name: null as (string | null),
    baer_name: null,
    assessment_status_id: null,
    assessment_status_ids: [1, 3, 4],
    fire_complexity_id: null,
    team_lead_id: null,
  }
  filters_clean = true;

  constructor(
    public dialog: MatDialog,
    private readonly assessmentService: AssessmentService,
    private readonly dialogService: DialogService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    private readonly forestFilterPipe: ForestFilterPipe,
    private reportsService: ReportService,
    private readonly toast: ConfirmationToastComponent,
  ) {
    this.titleService.setTitle("BAER - Assessments")
    this.regions = this.assessmentService.getRegionList();
    this.statuses = this.assessmentService.getStatusList().filter((x: any) => [1, 3, 4, 11].includes(x.value));;
    this.complexities = this.assessmentService.getComplexityList();
    this.userService.getPossibleLeadList().subscribe((team_leads: any) => { this.team_leads = team_leads.users });
    this.forestNameList = this.assessmentService.getForestNameList();
    this.filtered_forest_names = of(this.forestNameList)

    this.assessmentService.finishLoadingSubscription().subscribe(() => {
      this.regions = this.assessmentService.getRegionList();
      this.statuses = this.assessmentService.getStatusList().filter((x: any) => [1, 3, 4, 11].includes(x.value));
      this.complexities = this.assessmentService.getComplexityList();
      this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);

      this.filtered_forest_names = of(this.forestNameList)
      this.filtered_forest_names = this.forest_name.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter((value as string) || ""))
      )
    })
  }

  regionChanged(): void {
    this.forestNameList = this.assessmentService.getForestNameList(this.filters.region as any);
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnInit(): void {
    this.loadUser();

    this.displayedColumns = ['collapse',
      'baer_name',
      'region',
      'assessment_status_id',
      'fire_complexity_id',
      'team_needs',
      'team_lead',
      'actions'
    ]
    this.filtered_forest_names = this.forest_name.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

   loadUser() {
      if (this.isWO) {
        this.loadAssessments();
        return;
      };
      if (this.isROCO) {
        this.filters.region = this.user.region;
        this.filters_clean = false;
        this.search();
        return;
      };
      if (this.isFOCO) {
        this.filters.region = this.user.region;
        this.forest_name.setValue(this.user.forest_name);
        this.filters_clean = false;
        this.search();
        return;
      };
      if (this.isTeamLead) {
        this.isTeamLead = true;
        this.filters_clean = false;
        this.loadAssessments(true);
        return;
      };
      //default filter by forest
      this.filters.region = this.user.region;
      this.forest_name.setValue(this.user.forest_name);
      this.filters_clean = false;
      this.search();

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forestNameList.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  search(): void {
    this.filters_clean = false;
    this.pageNumber = 0;
    this.loadAssessments();
  }

  clearSearch(): void {
    this.filters_clean = true;
    this.pageNumber = 0;
    this.filters = {
      region: null,
      forest_name: null,
      baer_name: null,
      assessment_status_id: null,
      assessment_status_ids: [1, 3, 4],
      fire_complexity_id: null,
      team_lead_id: null,
    }
    this.forest_name.setValue("");
    this.regionChanged();
    this.loadAssessments();
  }

  pageEvent(e: any) {
    this.pageNumber = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadAssessments();
  }

  addSupportMessage(assessment: any) {
    if (assessment.supports?.length > 0) {
      assessment.supports.forEach((support: any) => {
        if (support.support_completed) return
        let skillsText = ""
        if (support.support_type == 'Team Member(s)' && support.support_skills != "") {
          let skills = support.support_skills.split(", ")
          if (skills.length == 1) {
            skillsText = support.support_skills
          } else if (skills.length == 2) {
            skillsText = skills.join(" and ")
          } else {
            skills[skills.length - 1] = "and " + skills[skills.length - 1]
            skillsText = skills.join(", ")
          }
          skillsText = "\n\nSkills:\n" + skillsText
        }
        let addDetailsText = ""
        if (support.additional_details) {
          addDetailsText = `\n\nAdditional Details:\n${support.additional_details}`
        }
        assessment.support_tooltip = `Interregional Support Request\n\nType of Support:\n${support.support_type} ${skillsText}${addDetailsText}`
      })
    }
  }

  async loadAssessments(initialTeamLeadLoad?: boolean) {
    this.dialogService.openSpinner()
    this.filters.forest_name = this.forest_name.value ?? null;
    this.assessmentService.getAssessmentList(this.pageNumber + 1, this.pageSize, this.filters, this.sort, this.sortDirection, initialTeamLeadLoad).subscribe(
      {
        next: (data) => {
          this.assessments.data = data.assessments;
          this.length = data.totalResults;
          if (initialTeamLeadLoad) this.length = this.assessments.data.length;
          this.assessments.data.forEach((assessment: any) => {
            this.addSupportMessage(assessment)
          })
        },
        complete: () => {
          this.dialogService.closeSpinner();
          return true;
        },
        error: (err) => {
          return false;
        }
      });
  }

  sortChange(sortState: any) {
    this.sort = sortState.active;
    this.sortDirection = sortState.direction;
    this.pageNumber = 0;
    this.loadAssessments()
  }

  clearValue(event: any) {
    event.target.value = ""
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.forest_name.setValue("")
    }
    this.selectedForestFlag = false
  }

  forestSelected() {
    this.selectedForestFlag = true
  }

  complexityModal(): void {
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  editBaerTeamNeeds(assessment: any): void {
    this.dialog.open(EditAssessmentComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: assessment,
        isTeamLead: this.isTeamLead,
        user: this.user
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  requestSupport(assessment: any): void {
    this.dialog.open(RequestSupportComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: assessment,
      },
    }).afterClosed().subscribe((reload) => {
      this.titleService.setTitle("BAER - Assessments")
      if (reload) {
        this.loadAssessments();
      }
    })
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  createInterimReport(assessment: any) {
    this.dialogService.openSpinner()
    this.reportsService.getReportList(assessment.assessment_id).subscribe({
      next: (response) => {
        this.reportsService.createReport(assessment, response.reports.length + 1).subscribe({
          next: (response) => {
            this.dialogService.closeSpinner()
            this.toast.openSuccessSnackBar(`An interim report for ${assessment.baer_name} has been created.`);
            this.loadAssessments();
          },
          error: (response) => {
            this.dialogService.closeSpinner()
            this.toast.openErrorSnackBar(`There was an error creating the initial report.`, "Please try again.");
          },
        })
      },
      error: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openErrorSnackBar(`There was an error creating the interim report.`, "Please try again.");
      },
    })
  }

  createInitialReport(assessment: any) {
    this.dialogService.openSpinner()
    this.assessmentService.editAssessment({
      assessment_id: assessment.assessment_id,
      assessment_status_id: 3
    }).subscribe({
      next: (response) => { },
      error: (response) => { },
    })
    this.reportsService.createReport(assessment, 1).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openSuccessSnackBar(`An initial report for ${assessment.baer_name} has been created.`);
        this.loadAssessments();
      },
      error: (response) => {
        this.dialogService.closeSpinner()
        this.toast.openErrorSnackBar(`There was an error creating the initial report.`, "Please try again.");
      },
    })
  }
}