import { Component, Input, OnInit, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { EditFireComponent } from 'src/app/routes/fire-tracker/edit-fire/edit-fire.component';

@Component({
  selector: 'app-view-fire',
  templateUrl: './view-fire.component.html',
  styleUrl: './view-fire.component.scss'
})
export class ViewFireComponent implements OnInit {

  faEdit = faEdit;
  edit_fire = model<boolean>();
  @Input() fire? : any;
  @Input() updateFireSubject? : Subject<any>;
  editable = input.required<boolean>();

  constructor(
    public dialog: MatDialog,
  ) {}

  ngOnInit() {}

  edit() {
    this.dialog.open(EditFireComponent, {
      maxWidth: "900px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        fireRecord: this.fire,
      },
    }).afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.updateFireSubject?.next(null)
      }
    })
  }
}
