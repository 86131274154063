<header class="baer-header">
    <p id="table" class="site-page-title">Burned Area Emergency Response Fire Tracker</p>
    <p class="title-date">Data last updated: {{ last_updated_on.getValue() ? (last_updated_on.getValue() | date: 'MM/dd/yyyy') : '--'}}</p>
</header>
<main id="main-content" class="main-content">
    <div style="display: flex;">
        <div class="record_count">
            {{length}} Fires
        </div>
        <div class="button-row">
            <button class="usa-button" (click)="addNewFire()"><mat-icon>add</mat-icon>Add New
                Fire</button>
        </div>
    </div>
    <div class="search-bar">
        <mat-form-field appearance="outline" style="max-width: 120px;">
            <mat-label>Region</mat-label>
            <mat-select [(value)]="filters.region" (selectionChange)="regionChanged()">
                <mat-option [value]="null">--</mat-option>
                @for (region of regions; track region) {
                <mat-option [value]="region">{{region}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Forest</mat-label>
            <input #forest matInput [formControl]="forest_name" [matAutocomplete]="auto"
            (input)="filter()"
            (focus)="clearForest()" aria-label="Forest Name">
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="forestSelected()">
                @for (name of filtered_forest_names | async; track name) {
                <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fire/Complex Name</mat-label>
            <input
             matInput
             [(ngModel)]="filters.baer_name"
             aria-label="Fire/Complex Name"
             >
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 180px;">
            <mat-label>Status</mat-label>
            <mat-select [(value)]="filters.assessment_status_id">
                <mat-option [value]="null">--</mat-option>
                @for (status of statuses; track status) {
                <mat-option [value]="status.value">{{status.text}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 170px;">
            <mat-label>Complexity</mat-label>
            <mat-select [(value)]="filters.fire_complexity_id">
                <mat-option [value]="null">--</mat-option>
                @for (complexity of complexities; track complexity) {
                <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <button class="usa-button" (click)="search()"><mat-icon>search</mat-icon>Search</button>
        <button class="usa-button usa-button--secondary" (click)="clearSearch()" *ngIf="!filters_clean">Clear Filters</button>
    </div>
    <table mat-table [dataSource]="fires" class="center" matSort matSortDisableClear (matSortChange)="sortChange($event)">
        <ng-container matColumnDef="merge">
            <th mat-header-cell *matHeaderCellDef>Merge</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <div class="usa-checkbox">
                    <input class="usa-checkbox__input" id="{{fire.assessment_id}}" type="checkbox"
                        [(ngModel)]="fire.isSelected" value="{{fire.assessment_id}}" (change)="mergeCheck()" aria-label="Toggle Merge">
                    <label class="usa-checkbox__label usa-checkbox__label2" for="{{fire.assessment_id}}"> </label>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="info-containment-div">
                    Source
                    <mat-icon matTooltip='Indicates fire’s data source. "GIS” from National
                Incident Service database, "NEW" for any
                fires added manually, “MERGE” for multiple fire records combined.' matTooltipClass="tooltipClass"
                        class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
                </div>
            </th>

            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <div [ngSwitch]="fire.source">
                    <mat-chip *ngSwitchCase="'GIS'" class="chip-gis">{{fire.source}}</mat-chip>
                    <mat-chip *ngSwitchCase="'NEW'" class="chip-new">{{fire.source}}</mat-chip>
                    <mat-chip *ngSwitchCase="'MERGE'">{{fire.source}}</mat-chip>
                    <mat-chip *ngSwitchDefault>N/A</mat-chip>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
            <td mat-cell *matCellDef="let fire" class="prewrap" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'"
            >{{fire.region | line_break}}</td>
        </ng-container>
        <ng-container matColumnDef="forest_org_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ForestOrg Code</th>
            <td mat-cell *matCellDef="let fire" class="prewrap" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'"
            >{{fire.forest_org_code | line_break}}</td>
        </ng-container>
        <ng-container matColumnDef="baer_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fire/Complex Name</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                {{fire.baer_name || "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="forest_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Forest Name</th>
            <td mat-cell *matCellDef="let fire" class="prewrap" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'"
            >{{fire.forest_name | forest_filter | line_break}}</td>
        </ng-container>
        <ng-container matColumnDef="acres_fsonly">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. NFS Acres</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                {{(fire.acres_fsonly | number: '1.0-0') || "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="total_fire_acres">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Fire Acres</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                {{(fire.poly_acres | number: '1.0-0') || "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="containment_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Est. Cont. Date</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <div *ngIf="fire.containment_date_est;else containmentDate">{{(fire.containment_date_est  | date:"MM/dd/YYYY") || "--" }}</div>
                <ng-template #containmentDate>{{(fire.containment_date | date:"MM/dd/YYYY") || "--" }}</ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="assessment_status_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>

                <div class="info-containment-div">
                    Assessment Status
                    <mat-icon matTooltip="Shows which stage the fire's BAER Assessment is currently under."
                        matTooltipClass="tooltipClass"
                        class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
                </div>
            </th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <mat-chip [ngClass]="'chip-'+fire.assessment_status_id">
                    {{fire.assessment_status_id | statusBadge}}
                </mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="fire_complexity_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="info-containment-div">
                    Complexity
                    <mat-icon matTooltip='Learn more about BAER Assessment Complexity Levels.' matTooltipClass="tooltipClass"
                        class="info-blue fa-regular fa-circle-info" (click)="complexityModal()">info_outline</mat-icon>
                </div>
            </th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <div [ngSwitch]="fire.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let fire" [style.background-color]="fire.isSelected ? '#FFF5E1' : 'white'">
                <button mat-button (click)="editFire(fire)" style="min-width: 70px;">
                    <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon> Edit</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="13">
            <div class="no-data">
                <div class="no-data-icon">
                    <mat-icon class="no-data-icon1">search</mat-icon>
                    <mat-icon class="no-data-icon2">whatshot</mat-icon>
                </div>
                <div class="no-data-title">No fires found</div>
                <p>There are no fires that match your current filters. Try removing some of them to get better results.</p>
                <button class="usa-button usa-button--secondary" (click)="clearSearch()">Clear Filters</button>
            </div>
          </td>
        </tr>
    </table>
    <mat-paginator
        [length]="length"
        [pageIndex]="pageNumber"
        [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons (page)="pageEvent($event)"
        aria-label="Select page of fires">
    </mat-paginator>
</main>
