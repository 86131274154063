<div class="container" aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1 mat-dialog-title class="delete-title" *ngIf="data.users.length == 1">Remove User</h1>
        <h1 mat-dialog-title class="delete-title" *ngIf="data.users.length > 1">Remove Users</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="delete-close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="title_light">
            Are you sure you want to remove {{ userText }}?
        </div>
        <div class="alert-text" *ngIf="data.users.length == 1">
            <mat-icon class="material-icons alerting-icon">warning</mat-icon>
            @switch (data.role) {
                @case ('CO') {
                    The user will remain in the database and will be removed from the <b>Coordination roster</b>.
                }
                @case ('LO') {
                    The user will remain in the database and will be removed from the <b>Line Officer roster</b>.
                }
                @default {
                    This user will remain in the database and will be moved to the <b>unassigned users roster</b>.
                }
            }
        </div>
        <div class="alert-text" *ngIf="data.users.length > 1">
            <mat-icon class="material-icons alerting-icon">warning</mat-icon>
            @switch (data.role) {
                @case ('CO') {
                    These users will remain in the database and will be removed from the <b>Coordination roster</b>.
                }
                @case ('LO') {
                    These users will remain in the database and will be removed from the <b>Line Officer roster</b>.
                }
                @default {
                    These users will remain in the database and will be moved to the <b>unassigned users roster</b>.
                }
            }
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row-always">
            <button class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button class="usa-button remove" (click)="removeUser()">
                <i class="material-icons">person_off</i>
                Remove</button>
        </div>
    </div>
</div>