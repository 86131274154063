import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from "@angular/router";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  expanded: boolean = false;
  user: any;
  isLoggedIn: boolean = false;
  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.userService.getUserProfile().then((user) => this.user = user)
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
