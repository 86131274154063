<main id="main-content" class="main-content" *ngIf="user">
    <div style="display: flex;">
        <div class="record_count">
            {{length}} Assessments
        </div>
    </div>
    <div class="search-bar">
        <mat-form-field appearance="outline" style="max-width: 120px;">
            <mat-label>Region</mat-label>
            <mat-select [(value)]="filters.region" (selectionChange)="regionChanged()">
                <mat-option [value]="null">--</mat-option>
                @for (region of regions; track region) {
                <mat-option [value]="region">{{region}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Forest</mat-label>
            <input #forest matInput [formControl]="forest_name" [matAutocomplete]="auto" (input)="filter()"
                (focus)="clearForest()" aria-label="Forest Name">
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="forestSelected()">
                @for (name of filtered_forest_names | async; track name) {
                <mat-option [value]="name">{{name | forest_filter}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fire/Complex Name</mat-label>
            <input matInput [(ngModel)]="filters.baer_name" aria-label="Fire/Complex Name">
        </mat-form-field>
        <mat-form-field appearance="outline" style="min-width: 220px;">
            <mat-label>Assessment Status</mat-label>
            <mat-select [(value)]="filters.assessment_status_id">
                <mat-option [value]="null">--</mat-option>
                @for (status of statuses; track status) {
                <mat-option [value]="status.value">{{status.text}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 170px;">
            <mat-label>Complexity</mat-label>
            <mat-select [(value)]="filters.fire_complexity_id">
                <mat-option [value]="null">--</mat-option>
                @for (complexity of complexities; track complexity) {
                <mat-option [value]="complexity.value">{{complexity.text}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 170px;">
            <mat-label>Team Lead</mat-label>
            <mat-select [(value)]="filters.team_lead_id">
                <mat-option [value]="null">--</mat-option>
                @for (team_lead of team_leads; track team_lead) {
                <mat-option [value]="team_lead.user_id">{{team_lead.first_name}} {{team_lead.last_name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <button class="usa-button" (click)="search()"><mat-icon>search</mat-icon>Search</button>
        <button class="usa-button usa-button--secondary" (click)="clearSearch()" *ngIf="!filters_clean">Clear
            Filters</button>
    </div>
    <table mat-table [dataSource]="assessments" class="center" matSort matSortDisableClear multiTemplateDataRows
        (matSortChange)="sortChange($event)">
        <ng-container matColumnDef="collapse">
            <th mat-header-cell *matHeaderCellDef style="width: 48px;"></th>
            <td mat-cell *matCellDef="let assessment">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedAssessments = expandedAssessments === assessment ? null : assessment); $event.stopPropagation()">
                    @if (expandedAssessments === assessment) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    } @else {
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    }
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
            <td mat-cell *matCellDef="let assessment" class="prewrap">{{assessment.region | line_break}}</td>
        </ng-container>
        <ng-container matColumnDef="baer_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fire/Complex Name</th>
            <td mat-cell *matCellDef="let assessment">
                <a [routerLink]="'/fire-overview'" [queryParams]="{assessment_id: assessment.assessment_id}">
                    {{assessment.baer_name || "--"}}
                </a> 
            </td>
        </ng-container>
        <ng-container matColumnDef="forest_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Forest Name</th>
            <td mat-cell *matCellDef="let assessment" class="prewrap">{{assessment.forest_name | forest_filter |
                line_break}}</td>
        </ng-container>
        <ng-container matColumnDef="assessment_status_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="info-containment-div">
                    Assessment Status
                    <mat-icon matTooltip="Shows which stage the fire's BAER Assessment is currently under."
                        matTooltipClass="tooltipClass"
                        class="info-blue fa-regular fa-circle-info">info_outline</mat-icon>
                </div>
            </th>
            <td mat-cell *matCellDef="let assessment">
                <mat-chip [ngClass]="'chip-'+assessment.assessment_status_id">
                    {{assessment.assessment_status_id | statusBadge}}
                </mat-chip>
            </td>
        </ng-container>
        <ng-container matColumnDef="fire_complexity_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="info-containment-div">
                    Complexity
                    <mat-icon matTooltip='Learn more about BAER Assessment Complexity Levels.'
                        matTooltipClass="tooltipClass" class="info-blue fa-regular fa-circle-info"
                        (click)="complexityModal()">info_outline</mat-icon>
                </div>
            </th>
            <td mat-cell *matCellDef="let assessment">
                <div [ngSwitch]="assessment.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="team_needs">
            <th mat-header-cell *matHeaderCellDef>Team Needs</th>
            <td mat-cell *matCellDef="let assessment">{{assessment.team_needs?.text || '--'}}</td>
        </ng-container>
        <ng-container matColumnDef="team_lead">
            <th mat-header-cell *matHeaderCellDef>Team Lead</th>
            <td mat-cell *matCellDef="let assessment">
                @if (assessment.team_lead) {
                <mat-chip>{{assessment.team_lead?.first_name}} {{assessment.team_lead?.last_name}}</mat-chip>
                } @else if (assessment.support_tooltip) {
                <mat-chip class="chip-1" [matTooltip]='assessment.support_tooltip'
                    matTooltipClass="tooltipClass"><fa-icon class="fa-custom" [icon]="faWarning"></fa-icon>Interregional
                    Support</mat-chip>
                } @else {
                <span>--</span>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 270px;">Actions</th>
            <td mat-cell *matCellDef="let assessment" style="width: 270px;">
                <div class="action-div">
                    @if (isWO ||
                    (isTeamLead && (assessment.team_lead?.user_id == user.user_id)) ||
                    (isFOCO && (assessment.forest_name == user.forest_name)) ||
                    (isROCO && (assessment.region == user.region ))
                    ) {
                    <button class="first-action" *ngIf="(assessment.team_needs?.value || 0) == 0"
                        (click)="editBaerTeamNeeds(assessment); stopPropagation($event)">
                        <fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>Edit Team Needs
                    </button>
                    <button class="first-action" [routerLink]="'/fire-overview'"
                        [queryParams]="{assessment_id: assessment.assessment_id}"
                        *ngIf="[1,2].includes(assessment.team_needs?.value || 0)">
                        <mat-icon>visibility</mat-icon>View Fire Overview
                    </button>
                    <button class="menu-btn" [matMenuTriggerFor]="menu" aria-label="Individual assessment action list"
                        #t="matMenuTrigger" (click)="stopPropagation($event)" class="{{t.menuOpen ? 'menuOpen' : ''}}">
                        @if (t.menuOpen) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        }
                    </button>
                    <mat-menu #menu="matMenu" class="action-menu" yPosition="below">
                        <button mat-menu-item *ngIf="[1,2].includes(assessment.team_needs?.value || 0)"
                            (click)="editBaerTeamNeeds(assessment); stopPropagation($event)">
                            <fa-icon class="fa-sm" [icon]="faEdit"></fa-icon>
                            <span>Edit Team Needs</span>
                        </button>
                        <button mat-menu-item (click)="requestSupport(assessment); stopPropagation($event)"
                            *ngIf="!assessment.team_lead && (isWO || isROCO || isFOCO)">
                            <mat-icon>support</mat-icon>
                            <span>Interregional Support</span>
                        </button>
                        <button mat-menu-item *ngIf="assessment.assessment_status_id == 1 && isTeamLead"
                            (click)="createInitialReport(assessment); stopPropagation($event)">
                            <fa-icon class="fa-sm" [icon]="faPlus"></fa-icon>
                            <span>Create Initial Report</span>
                        </button>
                        <button mat-menu-item *ngIf="assessment.assessment_status_id != 1 && isTeamLead"
                            (click)="createInterimReport(assessment); stopPropagation($event)">
                            <fa-icon class="fa-sm" [icon]="faPlus"></fa-icon>
                            <span>Create Interim Report</span>
                        </button>
                        <button mat-menu-item [routerLink]="'/fire-overview'"
                            [queryParams]="{assessment_id: assessment.assessment_id}"
                            *ngIf="(assessment.team_needs?.value || 0) == 0">
                            <mat-icon>visibility</mat-icon>
                            <span>View Fire Overview</span>
                        </button>
                        <button mat-menu-item [routerLink]="'/fire-overview'" [queryParams]="{assessment_id: assessment.assessment_id, tab:1}" *ngIf="assessment.team_lead && isTeamLead">
                            <fa-icon class="fa-sm" [icon]="faUsers"></fa-icon>
                            <span>View Team Roster</span>
                        </button>
                    </mat-menu>
                    } @else {
                    <button class="btn-only" [routerLink]="'/fire-overview'"
                        [queryParams]="{assessment_id: assessment.assessment_id}">
                        <mat-icon>visibility</mat-icon>View Fire Overview
                    </button>
                    }
                </div>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let assessment" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                    [@detailExpand]="assessment == expandedAssessments ? 'expanded' : 'collapsed'">
                    @if (isWO ||
                    (isTeamLead && (assessment.team_lead?.user_id == user.user_id)) ||
                    (isFOCO && (assessment.forest_name == user.forest_name)) ||
                    (isROCO && (assessment.region == user.region ))
                    ) {
                    <app-report-list [assessment]="assessment" [isEditable]="true"
                        style="width: 100%;"></app-report-list>
                    } @else {
                    <app-report-list [assessment]="assessment" [isEditable]="false"
                        style="width: 100%;"></app-report-list>
                    }
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedAssessments === element"
            (click)="expandedAssessments = expandedAssessments === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="13">
                <div class="no-data">
                    <div class="no-data-icon">
                        <mat-icon class="no-data-icon1">search</mat-icon>
                        <mat-icon class="no-data-icon2">whatshot</mat-icon>
                    </div>
                    <div class="no-data-title">No assessments found</div>
                    <p>There are no assessments that match your current filters. Try removing some of them to get better
                        results.</p>
                    <button class="usa-button usa-button--secondary" (click)="clearSearch()">Clear Filters</button>
                </div>
            </td>
        </tr>
    </table>
    <mat-paginator [length]="length" [pageIndex]="pageNumber" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50]"
        showFirstLastButtons (page)="pageEvent($event)" aria-label="Select page of fires">
    </mat-paginator>
</main>