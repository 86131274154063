<mat-card>
  <mat-card-header>
    <mat-card-title>CONTACT DETAILS</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Contact Info">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="user()">
    <div class="data-div">
      <p class="data-title">First Name</p>
      <p class="data-info">{{user().first_name}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Last Name</p>
      <p class="data-info">{{user().last_name}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">E-mail address</p>
      <p class="data-info">{{user().email}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Phone number</p>
      <p class="data-info">{{user().phone_num || '--' | phone}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Agency</p>
      <p class="data-info" *ngIf="user().agency">{{user().agency.text || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Region</p>
      <p class="data-info">{{user().region || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Forest</p>
      <p class="data-info">{{user().forest_name || '--'}}</p>
    </div>
    <div class="data-div">
      <p class="data-title">Position(s)</p>
      <ng-container *ngFor="let role of user().baer_roles">
        <p class="data-info">{{role.text}}</p>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>