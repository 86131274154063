<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1>Remove Team Member</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p>Are you sure you want to remove <b>{{data.user_name}}</b> from the team roster?</p>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button remove" [mat-dialog-close]="1">
                <fa-icon class="fa-sm" [icon]="faUserSlash"></fa-icon>Remove</button>
        </div>
    </div>
</div>