<nav aria-label="Primary navigation" class="usa-nav">
    <div class="usa-nav__inner">
        <button class="usa-nav__close">
            <img src="assets/img/close.svg" alt="close">
        </button>
        <ul class="usa-nav__primary usa-accordion">
            <li class="usa-nav__primary-item">
                <a class="usa-nav__link" routerLink="/dashboard"><span>Dashboard</span></a>
            </li>
            <li class="usa-nav__primary-item">
                <a class="usa-nav__link" routerLink="/fire-tracker" *ngIf="showFireTracker"><span>Fire Tracker</span></a>
            </li>
            <li class="usa-nav__primary-item">
                <a class="usa-nav__link" routerLink="/assessments" *ngIf="showAssessments"><span>BAER Assessments</span></a>
            </li>
            <li class="usa-nav__primary-item">
                <a class="usa-nav__link" routerLink="/dashboard" *ngIf="showReports"><span>Reports</span></a>
            </li>
            <li class="usa-nav__primary-item">
                <a class="usa-nav__link" routerLink="/roster" *ngIf="showRoster"><span>Roster</span></a>
            </li>
            <li class="usa-nav__primary-item" *ngIf="showTestPages">
                <a class="usa-nav__link" routerLink="/data-manipulation"><span>Data Manipulation</span></a>
            </li>
        </ul>
    </div>
</nav>