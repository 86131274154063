import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { ReportService } from 'src/app/services/report.service';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { MatTableDataSource } from '@angular/material/table';
import { UserMemberService } from 'src/app/services/user-member.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, of, startWith } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';

enum ModalState {
  NEW_OR_EXISTING = "NEW_OR_EXISTING",
  SEARCH_SKILL = "SEARCH_SKILL",
  SELECT_SKILL = "SELECT_SKILL",
  ADD_NEW_USER = "ADD_NEW_USER",
  NEW_USER_SELECT_SKILL = "NEW_USER_SELECT_SKILL",
  EDIT_SKILL = "EDIT_SKILL"
}

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrl: './add-team-member.component.scss'
})
export class AddTeamMemberComponent implements OnInit {

  currentUser: any;
  faUsers = faUsers
  private readonly fireRecord: any = this.data.fireRecord;

  selectedUser: any;

  team_needs: any[];
  leadList: any[] = [];
  skillList: any[] = [];
  regions: any[];
  roles: any[];
  is_WO: boolean = false;


  formValid = false;
  users = new MatTableDataSource<any>();
  usersSource: any[] = [];
  displayedColumns: string[] = ["name", "skills"];
  state: ModalState = ModalState.NEW_OR_EXISTING;

  // User Vars
  forest_names: any;
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  agency_list: any;
  selectedForestFlag = false;
  userExists = false;
  lastCheckedEmail: any = "";

  fireForm = this.formBuilder.group({
    support_type: [""],
    editor: [""],
    role: [-1],
    skills: [""],
    other_skill: [""],
    new_or_existing: [""],
    search_skills: [""],
    user_name: [""],
    region: [""],
    radio: [""],
  });

  userForm = this.formBuilder.group({
    first_name: [""],
    last_name: [""],
    region: [""],
    forest_name: [""],
    agency_id: [-1, [Validators.min(0), Validators.required]],
    location_id: [-1],
    email: [""],
    phone_num: [""],
  });



  constructor(
    public dialogRef: MatDialogRef<AddTeamMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
    private readonly titleService: Title,
    private readonly userService: UserService,
    private readonly reportService: ReportService,
    private readonly userMemberService: UserMemberService,
    private readonly dialogService: DialogService,
  ) {
    this.titleService.setTitle("BAER - Add Team Member")
    dialogRef.disableClose = true;

    this.regions = this.assessmentService.getRegionList().slice();
    this.regions.unshift("--")
    this.roles = this.userMemberService.getRoleList();

    this.fireForm.valueChanges.subscribe(value => {
      this.checkValidForm();
    })

    this.team_needs = this.assessmentService.getBAERTeamNeeds();
    this.userService.getSkillList().subscribe(skillsList => {
      skillsList.forEach((skill: any) => {
        this.skillList.push({ name: skill.text, checked: false, skill_id: skill.value });
      })
      this.skillList.push({ name: "Other", checked: false, skill_id: 14 })
    });

    // User form section

    this.forest_names = this.assessmentService.getForestNameList();
    this.agency_list = this.userService.getAgencyList();

    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )

    // End user form section

  }

  continue() {
    if (this.state == ModalState.NEW_OR_EXISTING) {
      if (this.fireForm.value.new_or_existing == "Existing") {
        this.state = ModalState.SEARCH_SKILL
      } else if (this.fireForm.value.new_or_existing == "New") {
        this.state = ModalState.ADD_NEW_USER
      }
    } else if (this.state == ModalState.SEARCH_SKILL) {
      this.state = ModalState.SELECT_SKILL
    } else if (this.state == ModalState.ADD_NEW_USER) {
      this.state = ModalState.NEW_USER_SELECT_SKILL
    }
  }

  back() {
    if (this.state == ModalState.SEARCH_SKILL) {
      this.state = ModalState.NEW_OR_EXISTING
    } else if (this.state == ModalState.SELECT_SKILL) {
      this.state = ModalState.SEARCH_SKILL
      this.fireForm.patchValue({
        radio: this.selectedUser
      })
    } else if (this.state == ModalState.ADD_NEW_USER) {
      this.state = ModalState.NEW_OR_EXISTING
    } else if (this.state == ModalState.NEW_USER_SELECT_SKILL) {
      this.state = ModalState.ADD_NEW_USER
    }
  }

  checkValidForm() {
    this.formValid = true
    let invalidForm = true
    for (let item of this.skillList) {
      if (item.checked) {
        invalidForm = false
        break
      }
    }
    if (this.skillList[this.skillList.length - 1].checked) {
      if (this.fireForm.value.other_skill == "") {
        invalidForm = true;
      }
    }
    if (invalidForm) {
      this.formValid = false
    }
  }

  ngOnInit(): void {
    this.dialogService.openSpinner();
    // 0-BAER Reporting Tool Administrator
    // 4-BAER National Coordinator
    // 7-Washington Office Director
    // 16-WO Coordination
    // 17-WO Logistics
    // 22-Washington Office Line Officer
    // 23-Acting Washington Office Line Officer
    const woOffice = this.data.user.baer_roles.find((obj: any) => [0, 4, 7, 16, 17, 22, 23].includes(obj.value))
    this.is_WO = woOffice;

    this.loadUsers();

    if (this.data.member) { //edit member
      this.state = ModalState.EDIT_SKILL
      this.titleService.setTitle("BAER - Edit Team Member");
      for (let skill of this.data.member.skills) {
        this.skillList.find((item: any) => item.skill_id == skill.skill_id).checked = true
      }
      this.setTooltip([this.data.member.user])
      this.selectedUser = this.data.member.user;
      this.fireForm.patchValue({
        editor: this.data.member.editor,
        role: this.data.member.role_id,
        skills: this.data.member.skills,
        other_skill: this.data.member.skills.find((skill: any) => skill.skill_id == 14)?.other_skill
      })
    }
  }


  sumbitRequest(): void {
    this.dialogService.openSpinner()
    if (this.state == ModalState.NEW_USER_SELECT_SKILL) {
      if (this.userForm.value.phone_num?.length == 0) this.userForm.value.phone_num = undefined;
      this.userService.createNewUser({
        first_name: this.userForm.value.first_name!,
        last_name: this.userForm.value.last_name!,
        region: this.userForm.getRawValue().region ?? undefined,
        forest_name: this.userForm.getRawValue().forest_name ?? undefined,
        agency_id: this.userForm.value.agency_id,
        location_id: this.userForm.getRawValue().location_id,
        phone_num: this.userForm.value.phone_num,
        email: this.userForm.getRawValue().email!,
        BAER_role_list: [2],
        procedure: 'NEW_TO_ALL'
      }).subscribe({
        next: (response) => {
          this.userService.getUsersByEmail(this.userForm.getRawValue().email!).subscribe({
            next: (response) => {
              this.selectedUser = response.users[0] // Should only return the one new user
              this.addUserToReport()
            },
            error: (response) => {
              this.dialogService.closeSpinner();
              this.toast.openErrorSnackBar("There was an error fetching the new user info.", "Please try adding the user again.");
              this.dialogRef.close();
            },
          })
        },
        error: (response) => {
          this.dialogService.closeSpinner();
          this.toast.openErrorSnackBar("There was an error adding the new user.", "Please try adding the user again.");
          this.dialogRef.close();
        },
      })
    } else if(this.state == ModalState.EDIT_SKILL) {
      this.editUser();
    } else {
      this.addUserToReport();
    }
  }

  addUserToReport(): void {
    let otherSkill = this.skillList.find((skill: any) => skill.name == "Other")

    if (otherSkill.checked) {
      otherSkill.other_skill = this.fireForm.value.other_skill
    }

    this.userMemberService.addUserToReport(this.data.report.report_id, {
      user_id: this.selectedUser.user_id,
      role_id: this.fireForm.value.role as number,
      editor: this.fireForm.value.editor as unknown as boolean,
      memberSkills: this.skillList.filter((skill: any) => skill.checked)
    }).subscribe({
      next: (response) => {
        let completedSupports = this.checkIfAnySupportComplete()
        if (completedSupports.length > 0) {
          this.supportCompleted(completedSupports)
        } else {
          this.dialogService.closeSpinner();
          this.toast.openSuccessSnackBar(`Team member has been added to the report.`);
          this.dialogRef.close(true);
        }
      },
      error: (response) => {
        this.dialogService.closeSpinner();
        this.toast.openErrorSnackBar(`There was an error adding the team member.`, "Please try editing the assessment again.");
        this.dialogRef.close();
      },
    })
  }

  editUser(): void {
    let otherSkill = this.skillList.find((skill: any) => skill.name == "Other")

    if (otherSkill.checked) {
      otherSkill.other_skill = this.fireForm.value.other_skill
    }

    this.userMemberService.updateMember(this.data.member.user_id,this.data.report.report_id, {
      role_id: this.fireForm.value.role as number,
      editor: this.fireForm.value.editor as unknown as boolean,
      memberSkills: this.skillList.filter((skill: any) => skill.checked)
    }).subscribe({
      next: (response) => {
          this.dialogService.closeSpinner();
          this.toast.openSuccessSnackBar(`Team member information has been updated.`);
          this.dialogRef.close(true);
      },
      error: (response) => {
        this.dialogService.closeSpinner();
        this.toast.openErrorSnackBar(`There was an error updating the team member.`, "Please try editing the assessment again.");
        this.dialogRef.close();
      },
    })
  }

  checkIfAnySupportComplete(): any[] {
    let completed: any[] = [];
    this.data.report.supports.forEach((support: any) => {
      if (support.support_completed) {
        return
      }
      if (this.checkIfSupportComplete(support)) {
        completed.push(support)
      }
    })
    return completed;
  }

  checkIfSupportComplete(support: any): boolean {
    if (support.support_type == "Team Member(s)") {

      let newSkills: string[] = this.skillList.filter((skill: any) => skill.checked).map((skill: any) => skill.name)
      let neededSkills = support.support_skills.split(", ")
      let allUserSkills = this.data.report.users.map((user: any) => {
        return user.skills.map((skill: any) => skill.skill.text)
      }).flat().concat(newSkills)
      let remainingSkills = neededSkills.filter((skill: string) => {
        return !allUserSkills.includes(skill)
      })

      return remainingSkills.length == 0 // All skills have been filled

    } else if (support.support_type == "Team Lead") {
      return this.fireForm.value.role as number == 0 // Newest member is a Team Lead
    }
    return false;
  }

  updateSkill(skill: any) {
    skill.checked = !skill.checked
    this.checkValidForm();
  }

  loadUsers() {
    this.userService.getUserList().subscribe(data => {
      let users;
      if (this.data.isWO)
        users = data.users
      else
        users = data.users.filter((user: any) => user.region == this.data.assessment.region)
      this.usersSource = users
      this.setTooltip(users);
      this.users.data = users;
      this.dialogService.closeSpinner();
    });
  }

  setTooltip(users: any) {
    users.forEach((user: any) => {
      let tooltip = '';
      let tooltip2 = '';
      for (let i = 1; user.skills.length - 1 >= i; i++) {
        tooltip += user.skills[i].skill.text + '\n'
      }
      for (let i = 1; user.baer_roles.length - 1 >= i; i++) {
        tooltip2 += user.baer_roles[i].text + '\n'
      }
      user.tooltip = tooltip;
      user.tooltip2 = tooltip2
    });
  }

  radioChange(event: any) {
    this.selectedUser = event.value;
  }

  filterUsers() {
    let skillList = this.fireForm.value.search_skills as any as string[]
    if (skillList && skillList.length > 0) {
      this.users.data = this.usersSource.filter((user: any) => {
        let good = false
        let userSkillList = user.skills.map((object: any) => object.skill.text)
        skillList.forEach((skill: string) => {
          if (userSkillList.includes(skill)) {
            good = true;
          }
        })
        return good
      });
    } else {
      this.users.data = this.usersSource
    }
    if (this.fireForm.value.user_name) {
      let name = this.fireForm.value.user_name.toLocaleLowerCase()
      this.users.data = this.users.data.filter((user: any) => {
        return user.first_name.toLocaleLowerCase().includes(name) || user.last_name.toLocaleLowerCase().includes(name)
      })
    }
    if (!this.is_WO || (this.fireForm.value.region && this.fireForm.value.region != "--")) {
      let region = this.fireForm.value.region
      if (!this.is_WO) { // If not WO, only show users in your region
        region = this.data.user.region
      }
      this.users.data = this.users.data.filter((user: any) => {
        return user.region == region
      })
    }
    if (this.selectedUser && !this.users.data.includes(this.selectedUser)) {
      this.selectedUser = null
    }
  }

  supportCompleted(supports: any[]) {
    this.data.report.support.support_completed = true
    supports.forEach((support: any) => {
      support.support_completed = true
    })
    this.reportService.updateReport({
      report_id: this.data.report.report_id,
      supports: supports
    }).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner();
        this.toast.openSuccessSnackBar(`Team member has been added to the report.`);
        this.dialogRef.close({ supportsCompleted: supports });
      },
      error: (response) => {
        this.dialogService.closeSpinner();
        this.toast.openErrorSnackBar(`The team member was added, but there was an error updating the report.`, "Please try again if needed.");
        this.dialogRef.close();
      },
    })
  }


  // User Form Portion

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }

  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }



  regionChanged(): void {
    this.userForm.patchValue({ forest_name: undefined })
    this.forest_names = this.assessmentService.getForestNameList(this.userForm.value.region ?? undefined);
    this.filtered_forest_names = this.userForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  updateValidators(value: any, type: any) {
    let location_id = this.userForm.controls.location_id.value;
    let agency_id = this.userForm.value.agency_id;
    if (type == 'location_id') {
      location_id = value;
    }
    if (type == 'agency_id') {
      agency_id = value;
    }
    this.updateValidatorsCheck(location_id, agency_id);
  }

  updateValidatorsCheck(location_id: any, agency_id: any): void {
    if (location_id != 0) {
      this.userForm.controls.forest_name.clearValidators();
      this.userForm.controls.forest_name.setValue('');

      if (location_id != 1) {
        this.userForm.controls.region.clearValidators();
        this.userForm.controls.region.setValue(null);
      }
    }
    if (agency_id != 0 && location_id == 2) {
      this.userForm.controls.location_id.setValue(null);
    }
  }

  clearForest() {
    if (!this.selectedForestFlag) {
      this.userForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }

  forestNameSelected(event: any): void {
    this.selectedForestFlag = true
  }

  checkIfUserExists(): void {
    if (this.lastCheckedEmail == this.userForm.getRawValue().email) return // Prevent double event firing
    this.lastCheckedEmail = this.userForm.getRawValue().email
    if (this.userForm.getRawValue().email == "") {
      this.userExists = false;
      return
    }
    this.dialogService.openSpinner();
    this.userService.getUsersByEmail(this.userForm.getRawValue().email!).subscribe({
      next: (response) => {
        this.dialogService.closeSpinner();
        if (response.totalResults > 0) {
          this.userExists = true;
        } else {
          this.userExists = false;
        }
      },
      error: (response) => {
        this.userExists = false;
        this.dialogService.closeSpinner();
      },
    })
  }
}