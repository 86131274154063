import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';
import { EditSelfComponent } from '../user/edit-self/edit-self.component';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { DeleteUserComponent } from '../user/delete-user/delete-user.component';

@Component({
  selector: 'app-data-manipulation',
  templateUrl: './data-manipulation.component.html',
  styleUrls: ['./data-manipulation.component.scss']
})
export class DataManipulationComponent implements OnInit {
  user: any;

  constructor(
    private readonly dataManipulationService: DataManipulationService,
    public dialog: MatDialog,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {

  }

  ngOnInit(): void {
    this.userService.getUserProfile().then((user) => {
      this.user = user
    })
  }

  resetAssessmentData() {
    if (window.confirm('Are sure you want to delete all assessment data?')) {
      this.dataManipulationService.resetAssessmentData().subscribe((data) => {
        console.log(data)
      })
    }
  }

  resetReportData() {
    if (window.confirm('Are sure you want to delete all report data?')) {
      this.dataManipulationService.resetReportData().subscribe((data) => {
        console.log(data)
      })
    }
  }

  resetUserData() {
    if (window.confirm('Are sure you want to delete all user data?')) {
      this.dataManipulationService.resetUserData().subscribe((data) => {
        console.log(data)
      })
    }
  }

  editSelfUser(): void {
    this.dialog.open(EditSelfComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        user: this.user
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.userService.refreshUserProfile().then((user) => {
          console.log("new self user", user)
          this.user = user
        })
      }
    })
  }

  deleteSelf() {
    this.dialog.open(DeleteUserComponent, {
      width: "440px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
      data: {
        users: [this.user]
      }
    }).afterClosed().subscribe((reload) => {
      if (reload) {
        this.authService.logout();
        this.router.navigate(['login']);
      }
    })
  }
}