import { Injectable } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AssignedGuard {
  user: any;
  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {
  }

  async canActivate() {
    let user = await this.userService.getUserProfile();
    if (user?.baer_roles?.length > 0)
      return true;
    this.router.navigate(['./dashboard']);
    return false;
  }
}