<div class="container" cdkFocusInitial aria-modal="true">
    <div mat-dialog-title class="dialog-title">
        <h1>Edit BAER Team Needs</h1>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <form id="filter_form" class="filter_form" [formGroup]="fireForm">
            <div class="div-label-block">
                <h2>FIRE DETAILS</h2>
                <mat-label class="title">Fire/Complex name</mat-label>
                <mat-label>{{data.fireRecord.baer_name}}</mat-label>
                <mat-label class="title">Forest name</mat-label>
                <mat-label>{{data.fireRecord.forest_name | forest_filter}}</mat-label>
                <mat-label class="title">Region</mat-label>
                <mat-label>{{data.fireRecord.region}}</mat-label>
                <mat-label class="title">Complexity</mat-label>
                <mat-label class="chip-label" [ngSwitch]="data.fireRecord.fire_complexity_id">
                    <mat-chip *ngSwitchCase=1 class="chip-low">LOW</mat-chip>
                    <mat-chip *ngSwitchCase=2 class="chip-moderate">MODERATE</mat-chip>
                    <mat-chip *ngSwitchCase=0 class="chip-high">HIGH</mat-chip>
                    <div *ngSwitchDefault>--</div>
                </mat-label>
                <mat-label class="title">Assessment Status</mat-label>
                <mat-label class="chip-label">
                    <mat-chip [ngClass]="'chip-'+data.fireRecord.assessment_status_id">
                        {{data.fireRecord.assessment_status_id | statusBadge}}
                    </mat-chip>
                </mat-label>
                <mat-label class="title"></mat-label>
                <mat-label></mat-label>
            </div>
            @if (is_WO) {
            <div class="long-field-container">
                <mat-form-field appearance="outline" class="long-field">
                    <mat-label>BAER Team Needs</mat-label>
                    <mat-select formControlName="team_needs_id">
                        @for (need of team_needs; track need) {
                        <mat-option [value]="need.value">{{need.text}}</mat-option>
                        }
                    </mat-select>
                    <mat-icon
                        matTooltip="Prioritize local coverage for your team, then regional. If interregional support is needed, submit an interregional support request from the fire's action menu."
                        matTooltipClass="tooltipClass" class="info-blue-field fa-regular fa-circle-info"
                        aria-label="Info">info_outline</mat-icon>
                </mat-form-field>
            </div>
            <div class="title">BAER Team Lead</div>
            <div class="support-warning" *ngIf="data.fireRecord.support && fireForm.value.teamLeads == null">
                <fa-icon [icon]="faWarning" class="faWarning" aria-label="Warning"></fa-icon>
                <div class="support-text">
                    <span class="support-title">
                        Interregional Support Request
                    </span>
                    <br>
                    <span class="support-description">
                        A <b>team lead</b> needs to be assigned. Select a different <b>region</b> to search for a
                        <b>team lead.</b>
                    </span>
                </div>
            </div>
            <mat-form-field appearance="outline" class="quarter-field">
                <mat-label>Region</mat-label>
                <mat-select formControlName="region" (selectionChange)="regionChanged()">
                    @for (region of regions; track region) {
                    <mat-option [value]="region">{{region}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            } @else {
            <mat-form-field appearance="outline">
                <mat-label>BAER Team Needs</mat-label>
                <mat-select formControlName="team_needs_id">
                    @for (need of team_needs; track need) {
                    <mat-option [value]="need.value">{{need.text}}</mat-option>
                    }
                </mat-select>
                <mat-icon
                    matTooltip="Prioritize local coverage for your team, then regional. If interregional support is needed, submit an interregional support request from the fire's action menu."
                    matTooltipClass="tooltipClass" class="info-blue-field fa-regular fa-circle-info"
                    aria-label="Info">info_outline</mat-icon>
            </mat-form-field>
            }

            @if (leadList.length == 0) {
            <mat-form-field appearance="outline" [class.three-quarter-field]="is_WO">
                <mat-label>BAER Team Lead</mat-label>
                <mat-select [disabled]="true" [value]="''">
                    <mat-option value="" class="lead-option">No team leads available</mat-option>
                </mat-select>
            </mat-form-field>
            } @else {
            <mat-form-field appearance="outline" [class.three-quarter-field]="is_WO">
                <mat-label>BAER Team Lead</mat-label>
                <mat-select formControlName="teamLeads">
                    <mat-select-trigger>
                        {{getLeadName()}}
                    </mat-select-trigger>
                    <mat-option [value]="null" class="lead-option">--</mat-option>
                    @for (user of leadList; track user) {
                    <mat-option class="lead-option" [value]="user.user_id">
                        <div class="lead-name">
                            {{ user.first_name + " " + user.last_name }}
                        </div>
                        <div class="assignment-details" *ngIf="user.filteredAssessment; else availableDef">
                            <div class="fire-title">Fire Assignment:</div>
                            <div>
                                <mat-icon class="no-data-icon2">whatshot</mat-icon>
                                <span class="fire-title">Fire:&nbsp;</span>{{user.filteredAssessment.baer_name}}
                            </div>
                            <div>
                                <mat-icon class="no-data-icon2">event_note</mat-icon>
                                <span class="fire-title">Start:&nbsp;</span>{{user.filteredAssessment.start_date}}
                                <span class="fire-title" style="padding-left: 10px;">Close:&nbsp;</span>
                                {{user.filteredAssessment.close_date}}
                            </div>
                        </div>
                        <ng-template #availableDef>
                            <div class="available">
                                <mat-icon class="icon_available">check</mat-icon> Available For Assignment
                            </div>
                        </ng-template>
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>

            }
            <div class="whole-line">
                <div class="title">Are you willing to accept trainees?</div>
                <mat-radio-group required formControlName="trainees_needed" class="radio-buttons">
                    <mat-radio-button [value]="true" color="primary" class="radio-button">Yes</mat-radio-button>
                    <mat-radio-button [value]="false" color="primary">No</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="whole-line">
                <div class="title">Notes<span class="gray-text">(Concerns, highlights, staffing needs)</span>
                </div>
                <mat-form-field class="text-area" appearance="outline">
                    <textarea matInput formControlName="notes" placeholder="Enter description"></textarea>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Tentative BAER Start Date</mat-label>
                <input matInput formControlName="tentative_start_week" [matDatepicker]="picker1"
                    placeholder="MM/DD/YYYY" aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Team Close-out Date</mat-label>
                <input matInput formControlName="close_out_date" [matDatepicker]="picker2" placeholder="MM/DD/YYYY"
                    aria-label="Estimated Containment Date">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <div class="whole_width row" *ngIf="!is_WO">
                <div>
                    If additional team leads or member(s) support will be required outside the region, select
                    Interregional Support from the fire's actions menu.
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="button-row">
            <button aria-label="Cancel" class="usa-button usa-button--secondary" mat-dialog-close>Cancel</button>
            <button aria-label="Update" class="usa-button" [disabled]="!fireForm.valid"
                (click)="editAssessment()">Save</button>
        </div>
    </div>
</div>