<mat-card>
  <mat-card-header>
    <mat-card-title>AVAILABILITY</mat-card-title>
    <button class="usa-button usa-button--secondary" (click)="edit()" style="min-width: 70px;" *ngIf="editable()" aria-label="Edit Availability">
      <fa-icon [icon]="faEdit" style="color: #216E1F;"></fa-icon>&nbsp;Edit
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="user()">
    <div class="outer_div">
      <div class="inner_div">
        <div class="data-div">
          <p class="data-title">Availability</p>
          <div class="available-list">
            <mat-chip *ngIf="user().available_local">Available Locally</mat-chip>
            <mat-chip *ngIf="user().available_region">Available Regionally</mat-chip>
            <mat-chip *ngIf="user().available_nation">Available Nationally</mat-chip>
            <p *ngIf="!user().available_local && !user().available_region && !user().available_nation">--</p>
          </div>
        </div>
        <div class="data-div">
          <div class="data-title">Conflict dates <div class="data-subtitle">(Known unavailable dates)</div>
          </div>
          <p class="data-info">{{user().conflict_dates || '--'}}</p>
        </div>
      </div>
      <div class="inner_div">
        <div class="data-div" style="display: flex; flex-wrap: wrap;">
          <div class="data-title" style="width: 100%;">Certification</div>
          <br />
          <p style="width: 50%;">
            NIS-BAER: {{user().NIS_BAER || 'false'}}
          </p>
          <p *ngIf="user().VGIS">
            VGIS: {{user().VGIS || 'false'}}
          </p>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>