<mat-card>
  <mat-card-header>
    <mat-card-title>CONTACT DETAILS</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="contact_form" [formGroup]="contactForm">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="first_name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="last_name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>E-mail address</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phone" placeholder="(XXX) XXX-XXXX" mask="(000) 000-0000">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Agency</mat-label>
        <mat-select formControlName="agency">
          @for (agency of agencies; track agency) {
          <mat-option [value]="agency.value">{{agency.text}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Region</mat-label>
        <mat-select formControlName="region" (selectionChange)="regionChanged()">
          <mat-option [value]="null">--</mat-option>
          <mat-option value="WO">WO</mat-option>
          @for (region of regions; track region) {
          <mat-option [value]="region">{{region}}</mat-option>
          }
        </mat-select>
        <mat-error>Required Field</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Forest Name</mat-label>
        <input #forest matInput formControlName="forest_name" [matAutocomplete]="auto" 
          (input)="filter()"
          (focus)="clearForest()">
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete requireSelection #auto="matAutocomplete"  (optionSelected)="forestNameSelected($event)">
          @for (name of filtered_forest_names | async; track name) {
          <mat-option [value]="name">{{name | forest_filter}}</mat-option>
          }
        </mat-autocomplete>
        <mat-error>Required Field</mat-error>
      </mat-form-field>
      <div class="flex:20%">&nbsp;</div>
    </form>
  </mat-card-content>
  <mat-dialog-actions>
    <div class="button-row">
      <button class="usa-button" [disabled]="!contactForm.valid" (click)="save()">Save</button>
      <button class="usa-button usa-button--secondary" (click)="cancel()">Cancel</button>
    </div>
  </mat-dialog-actions>
</mat-card>