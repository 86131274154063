import { Component, input, model } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.component.html',
  styleUrl: './view-contact.component.scss'
})
export class ViewContactComponent {
  faEdit = faEdit;
  edit_contact = model<boolean>();
  user = input.required<any>();
  editable = input.required<boolean>();

  edit() {
    this.edit_contact.update((x) => {
      return true;
    });
  }
}
