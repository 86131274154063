import {inject, PLATFORM_ID} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

export const authGuard = (
  activatedRouteSnapshot: ActivatedRouteSnapshot
) => {
  const authService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformBrowser(platformId)) {
    sessionStorage.setItem("previous_uri", encodeURI(activatedRouteSnapshot.url.toString()));
    sessionStorage.setItem("previous_query_params", JSON.stringify(activatedRouteSnapshot.queryParams))
  }
  if (authService.checkCredentials()) {
    return true
  }
  const router = inject(Router)
  return router.navigate(['login']);
};
