import {NativeDateAdapter} from "@angular/material/core";

export class CustomDateAdapter extends NativeDateAdapter {
  constructor() {
    super();
  }

  override deserialize(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
      const str = value.split('-');
      const dayArray = str[2].split('T');
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const day = Number(dayArray[0]);
      return new Date(new Date(year, month, day).getTime() + 60 * 1000 * new Date().getTimezoneOffset())
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

}
