import {Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ConfirmationToastComponent } from '../../../components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { Observable, map, of, startWith } from 'rxjs';
import { ComplexityModalComponent } from '../complexity-modal/complexity-modal.component';
import { Title } from '@angular/platform-browser';
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: 'app-new-fire',
  templateUrl: './new-fire.component.html',
  styleUrl: './new-fire.component.scss'
})
export class NewFireComponent implements OnInit {

  private readonly isBrowser: boolean;

  regions: any;
  forest_names: any;
  filtered_forest_names: Observable<string[]>;
  @ViewChild('forest') input?: ElementRef<HTMLInputElement>;
  orgCodes: any;
  status_list: any;
  complexities: any;
  selectedForestFlag = false;

  newFireForm = this.formBuilder.group({
    fire_complex_name: [""],
    region: [""],
    forest_name: [""],
    forestOrgCode: [""],
    est_nfs_acres: [""],
    containment_date: [null] as unknown as Date[],
    est_containment_date: [null] as unknown as Date[],
    status: [""],
    complexity: [-1],
  });

  constructor(
    public dialogRef: MatDialogRef<NewFireComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toast: ConfirmationToastComponent,
    private assessmentService: AssessmentService,
    private titleService: Title,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.titleService.setTitle("BAER - New Fire")
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.orgCodes = this.assessmentService.getOrgCodeList();
    this.status_list = this.assessmentService.getStatusList();
    this.status_list = this.status_list.filter((item: any) => [1,2,3].includes(item.value))

    this.complexities = this.assessmentService.getComplexityList();

    this.filtered_forest_names = this.newFireForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      import('inputmask').then((Inputmask: any) => {
        let nfsAcres = document.getElementById("nfsAcres") ?? '';
        let im = new Inputmask("decimal", {
          groupSeparator: ',',
          allowMinus: false
        });
        im.mask(nfsAcres);
      })
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.forest_names.filter((option: string) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  filter(): void {
    const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
    this.filtered_forest_names = of(this._filter(filterValue));
  }

  addNewFire(): void {
    let containment_date: Date = this.newFireForm.value.containment_date as unknown as Date
    let containment_date_est: Date = this.newFireForm.value.est_containment_date as unknown as Date
    let containment_date_string = undefined;
    let containment_date_est_string = undefined;
    if (containment_date) {
      containment_date_string = containment_date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    if (containment_date_est) {
      containment_date_est_string = containment_date_est.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }

    let complexity = this.newFireForm.value.complexity
    if (complexity == -1) {
      complexity = undefined
    }

    this.assessmentService.createNewAssessment({
      baer_name: this.newFireForm.value.fire_complex_name!,
      region: this.newFireForm.value.region!,
      forest_name: this.newFireForm.value.forest_name!,
      forest_org_code: this.newFireForm.value.forestOrgCode!,
      acres_fsonly: +this.newFireForm.value.est_nfs_acres!.replace(/,/g, ''),
      containment_date: containment_date_string,
      containment_date_est: containment_date_est_string,
      assessment_status_id: (this.newFireForm.value.status? this.newFireForm.value.status : undefined) as number | undefined,
      fire_complexity_id: complexity as number | undefined
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.newFireForm.value.fire_complex_name} was added to the fire tracker.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar("There was an error adding the new fire.", "Please try adding the fire record again.");
        this.dialogRef.close();
      },
    })
  }

  regionChanged(): void {
    this.forest_names = this.assessmentService.getForestNameList(this.newFireForm.value.region ?? undefined);
    this.filtered_forest_names = this.newFireForm.controls["forest_name"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filter((value as string) || ""))
    )
    this.orgCodes = this.assessmentService.getOrgCodeList(this.newFireForm.value.region ?? undefined);
    if (!this.forest_names.includes(this.newFireForm.value.forest_name)) {
      this.newFireForm.patchValue({
        forest_name: ""
      })
    }
  }

  forestNameSelected(event: any): void {
    let orgCode = this.assessmentService.getOrgCodeFromForest(event.option.value)
    this.newFireForm.patchValue({
      forestOrgCode: orgCode,
      region: orgCode?.substring(0,2),
    })
    this.selectedForestFlag = true
  }

  orgCodeSelected(event: any): void {
    this.newFireForm.patchValue({
      forest_name: this.assessmentService.getForestFromOrgCode(event.value),
      region: event.value.substring(0,2),
    })
  }

  // Clear org code if selected and backspace or delete or pressed
  orgCodeKeyPress(event: KeyboardEvent) {
    const key = event.key;
    if (key == "Backspace" || key == "Delete") {
      this.newFireForm.patchValue({forestOrgCode: null})
    }
  }

  complexityModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }

  clearForest() {
    if (!this.selectedForestFlag){
      this.newFireForm.patchValue({
        forest_name: ""
      })
    }
    this.selectedForestFlag = false
  }
}
