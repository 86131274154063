import { Pipe } from "@angular/core";

@Pipe({ name: "forest_filter" })
export class ForestFilterPipe {
    transform(forest_name: string): string {
    return forest_name.replaceAll(' National Forests',' NFs')
        .replaceAll(' National Forest',' NF')
        .replaceAll('National Forests ','NF ')
        .replaceAll(' National Scenic Area',' NSA')
        .replaceAll(' National Recreation Area',' NRA')
        .replaceAll(' Management Unit',' MU');
    }
}