import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { ComplexityModalComponent } from '../complexity-modal/complexity-modal.component';
import { Title } from '@angular/platform-browser';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-merge-fires',
  templateUrl: './merge-fires.component.html',
  styleUrl: './merge-fires.component.scss'
})
export class MergeFiresComponent implements OnInit {
  private readonly isBrowser: boolean;

  private mergeList: any = this.data.mergeList;
  fires?: any;
  est_nfs_acres_total = 0;
  total_fire_acres_total = 0;
  containment_date_latest: Date = new Date('1/1/1900');
  est_containment_date_latest: Date = new Date('1/1/1900');
  status = 1;
  complexity_highest = 0;

  regions: any;
  forest_names: any;
  orgCodes: any;
  status_list: any;
  complexities: any;

  displayedColumns!: string[];

  mergeForm = this.formBuilder.group({
    fire_complex_name: [""],
    est_nfs_acres: [""],
    containment_date: [undefined as Date | undefined],
    est_containment_date: [undefined as Date | undefined],
    status: [new Number],
    complexity: [new Number],
  });

  constructor(
    public dialogRef: MatDialogRef<MergeFiresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private assessmentService: AssessmentService,
    private toast: ConfirmationToastComponent,
    private titleService: Title,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.titleService.setTitle("BAER - Merge Fires")
    dialogRef.disableClose = true;
    this.regions = this.assessmentService.getRegionList();
    this.forest_names = this.assessmentService.getForestNameList();
    this.orgCodes = this.assessmentService.getOrgCodeList();
    this.status_list = this.assessmentService.getStatusList();
    this.complexities = this.assessmentService.getComplexityList();
  }
  ngOnInit(): void {
    this.fires = this.data.fireRecord;

    this.displayedColumns = [
      'baer_name',
      'forestOrgCode',
      'containment_date',
      'est_nfs_acres',
      'fire_acres',
    ]

    this.mergeList = []

    this.fires.forEach((fire: any, index: number) => {
      this.est_nfs_acres_total += fire.acres_fsonly || 0;
      this.total_fire_acres_total += fire.poly_acres || 0;
      this.mergeList.push(fire.assessment_id)
      if (fire.fire_complexity_id > this.complexity_highest) this.complexity_highest = fire.fire_complexity_id;
      if (new Date(fire.containment_date) > new Date(this.containment_date_latest))
        this.containment_date_latest = new Date(fire.containment_date)
      if (new Date(fire.containment_date_est) > new Date(this.est_containment_date_latest))
        this.est_containment_date_latest = new Date(fire.containment_date_est)
    });

    this.est_nfs_acres_total = Math.round(this.est_nfs_acres_total)

    let containment_date_latest = ''
    if (this.containment_date_latest.getTime() != new Date('1/1/1900').getTime()
      && this.containment_date_latest.getTime() != new Date(null as any).getTime())
      containment_date_latest = this.containment_date_latest.toString()
    let est_containment_date_latest = ''
    if (this.est_containment_date_latest.getTime() != new Date('1/1/1900').getTime()
      && this.est_containment_date_latest.getTime() != new Date(null as any).getTime())
      est_containment_date_latest = this.est_containment_date_latest.toString()
    // The date below will be an invalid date, checking if so and setting to undefined
    let est_containment_date_latest_date: Date | undefined = new Date(est_containment_date_latest)
    if (isNaN(est_containment_date_latest_date.getTime())) {
      est_containment_date_latest_date = undefined;
    }
    let containment_date_latest_date: Date | undefined = new Date(containment_date_latest)
    if (isNaN(containment_date_latest_date.getTime())) {
      containment_date_latest_date = undefined;
    }
    this.mergeForm.patchValue({
      est_nfs_acres: this.est_nfs_acres_total.toString(),
      containment_date: containment_date_latest_date,
      est_containment_date: est_containment_date_latest_date,
      status: this.status,
      complexity: this.complexity_highest
    })

    if (this.isBrowser) {
      import('inputmask').then((Inputmask: any) => {
        let nfsAcres = document.getElementById("nfsAcres") ?? '';
        let im = new Inputmask("decimal", {
          groupSeparator: ',',
          allowMinus: false
        });
        im.mask(nfsAcres);
      })
    }
  }

  merge() {
    let containment_date: Date = this.mergeForm.value.containment_date as unknown as Date
    let containment_date_est: Date = this.mergeForm.value.est_containment_date as unknown as Date
    let containment_date_string = undefined;
    let containment_date_est_string = undefined;
    if (containment_date) {
      containment_date_string = containment_date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
    }
    if (containment_date_est) {
      containment_date_est_string = containment_date_est.toLocaleDateString("en-US", {year:"numeric",month:"2-digit",day:"2-digit"})
    }
    this.assessmentService.mergeAssessments({
      baer_name: this.mergeForm.value.fire_complex_name!,
      acres_fsonly: +this.mergeForm.value.est_nfs_acres!?.replaceAll(',',''),
      containment_date: containment_date_string,
      containment_date_est: containment_date_est_string,
      assessment_status_id: this.mergeForm.value.status as number | undefined,
      fire_complexity_id: this.mergeForm.value.complexity as number | undefined,
      assessment_id_list: this.mergeList,
      poly_acres: this.total_fire_acres_total || undefined
    }).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`${this.mergeForm.value.fire_complex_name} record has been updated.`);
        this.dialogRef.close(true);
      },
      error: (response) => {
        this.toast.openErrorSnackBar("There was an error merging records.", "Please try merging the records again.");
        this.dialogRef.close();
      },
    })
  }

  complexityModal(event: any): void {
    event.stopPropagation();
    this.dialog.open(ComplexityModalComponent, {
      width: "700px",
      panelClass: ['animate__animated', 'animate__fadeInDown'],
    })
  }
}
