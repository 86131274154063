import { Component, Input, OnInit, SimpleChanges, input, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { ConfirmationToastComponent } from 'src/app/components/confirmation-toast/confirmation-toast.component';
import { AssessmentService } from 'src/app/services/assessment.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrl: './reports-tab.component.scss'
})
export class ReportsTabComponent implements OnInit {

  edit_fire = signal<boolean>(false);

  //data
  @Input() fire?: any;
  @Input() reports: any;
  @Input() updateFireSubject?: Subject<any>;
  @Input() user: any;
  
  //access
  @Input() isTeamLead: boolean = false;
  @Input() isEditable = true;

  constructor(
    private titleService: Title,
    private reportsService: ReportService,
    private readonly toast: ConfirmationToastComponent,
    private readonly assessmentService: AssessmentService,
  ) {
    this.titleService.setTitle("BAER - 2500-8 Reports")
  }

  ngOnInit(): void {
  }

  createInterimReport() {
    this.reportsService.createReport(this.fire, this.reports?.length + 1).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`An interim report for ${this.fire.baer_name} has been created.`);
        this.updateFireSubject?.next(null)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error creating the interim report.`, "Please try again.");
      },
    })
  }

  createInitialReport() {
    this.assessmentService.editAssessment({
      assessment_id: this.fire.assessment_id,
      assessment_status_id: 3
    }).subscribe({
      next: (response) => {},
      error: (response) => {},
    })
    this.reportsService.createReport(this.fire, this.reports?.length + 1).subscribe({
      next: (response) => {
        this.toast.openSuccessSnackBar(`An initial report for ${this.fire.baer_name} has been created.`);
        this.updateFireSubject?.next(null)
      },
      error: (response) => {
        this.toast.openErrorSnackBar(`There was an error creating the initial report.`, "Please try again.");
      },
    })
  }
}
